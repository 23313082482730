// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-islah-24-js": () => import("./../../../src/pages/about/islah-24.js" /* webpackChunkName: "component---src-pages-about-islah-24-js" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-account-paydues-tsx": () => import("./../../../src/pages/account/paydues.tsx" /* webpackChunkName: "component---src-pages-account-paydues-tsx" */),
  "component---src-pages-connect-google-redirect-tsx": () => import("./../../../src/pages/connect/google/redirect.tsx" /* webpackChunkName: "component---src-pages-connect-google-redirect-tsx" */),
  "component---src-pages-dev-js": () => import("./../../../src/pages/dev.js" /* webpackChunkName: "component---src-pages-dev-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-service-mudhorobah-tsx": () => import("./../../../src/pages/service/mudhorobah.tsx" /* webpackChunkName: "component---src-pages-service-mudhorobah-tsx" */),
  "component---src-pages-service-musyarakah-tsx": () => import("./../../../src/pages/service/musyarakah.tsx" /* webpackChunkName: "component---src-pages-service-musyarakah-tsx" */),
  "component---src-pages-transaction-confirmation-tsx": () => import("./../../../src/pages/transaction/confirmation.tsx" /* webpackChunkName: "component---src-pages-transaction-confirmation-tsx" */),
  "component---src-pages-transaction-create-tsx": () => import("./../../../src/pages/transaction/create.tsx" /* webpackChunkName: "component---src-pages-transaction-create-tsx" */),
  "component---src-pages-transaction-detail-tsx": () => import("./../../../src/pages/transaction/detail.tsx" /* webpackChunkName: "component---src-pages-transaction-detail-tsx" */),
  "component---src-pages-transaction-index-tsx": () => import("./../../../src/pages/transaction/index.tsx" /* webpackChunkName: "component---src-pages-transaction-index-tsx" */)
}

